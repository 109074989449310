// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {

  interface String {
    capitalize: () => String | string;
  }

  interface StringConstructor {
    isString(value: any): boolean;
  }
}

String.prototype.capitalize = function (): String | string {
  return this[0].toUpperCase() + this.slice(1);
}

String.isString = function (value: any): boolean {
  return ((typeof value === 'string') || (value instanceof String));
}
