import { FetchError } from 'ofetch';
import './Date';
import './Array';
import './Object';

declare global {
  // IUser structure
  interface IUser {
    id: Number | String | null;
    name: String | null;
    surname: String | null;
    phone: Phone;
    mobile: Phone;
    email: String | null;
    created_at: Date | String | null;
    updated_at: Date | String | null;
    deleted_at: Date | String | null;
    email_verified_at: Date | String | null;
    password_changed_at: Date | String | null;
    password?: String | null | undefined;
    remember?: boolean | undefined;
    roles: Array<{ name: String; permissions: Array<{ name: String }> }>;
    organization?: Object | null | undefined;
    otpAttachments?: Array<FileItem> | null | undefined;
  }

  type IEUser = Omit<IUser, "roles">;
  // IAuth structure
  interface IAuth extends IEUser {
    login: (data?: IAuth) => Promise<any>;
    logged: () => boolean;
    logout: () => Promise<any>;
    refresh: () => Promise<any>;
    hasPermission: (...permissions: Array<String>) => boolean;
    routeAvailable: (route: String) => boolean;
    fetchProfile: () => Promise<any>;
    roles: Array<String>;
    permissions: Array<String>;
    otpAttachments?: Array<FileItem> | null | undefined;
  }

  // class Auth implements IAuth {
  //   id: Number | String | null;
  //   name: String | null;
  //   surname: String | null;
  //   phone: Phone;
  //   mobile: Phone;
  //   email: String | null;
  //   created_at: Date | String | null;
  //   updated_at: Date | String | null;
  //   deleted_at: Date | String | null;
  //   email_verified_at: Date | String | null;
  //   password_changed_at: Date | String | null;
  //   roles: Array<String>;
  //   permissions: Array<String>;
  //   organization: Object | null | undefined;
  //   otpAttachments: Array<FileItem> | null | undefined;

  //   // Instance check bcs. typescript
  //   private static instanceOfUser(object: any): object is IUser;

  //   // Init
  //   private init(object?: IUser | null): void;

  //   // C'tor
  //   public constructor(object?: IUser | null);

  //   // Logged is authenticated
  //   public logged(): boolean;

  //   // Login method
  //   public login(data?: IAuth): Promise<any>;

  //   // Logout method
  //   public logout(): Promise<any>;

  //   // Refresh method
  //   public refresh(): Promise<any>;

  //   // Permission check method
  //   public hasPermission(...permissions: Array<String>): boolean;

  //   // Role check method
  //   public hasRole(...roles: Array<String>): boolean;

  //   // Permission check method
  //   public routeAvailable(to: any): boolean;

  //   // Fetch profile
  //   public fetchProfile(): Promise<any>;
  // }
}

export default class Auth implements IAuth {
  id: Number | String | null = null;
  name: String | null = null;
  surname: String | null = null;
  phone = <Phone>{
    prefix: null,
    number: null,
  };
  mobile = <Phone>{
    prefix: null,
    number: null,
  };
  email: String | null = null;
  created_at: Date | String | null = null;
  updated_at: Date | String | null = null;
  deleted_at: Date | String | null = null;
  email_verified_at: Date | String | null = null;
  password_changed_at: Date | String | null = null;
  roles: Array<String> = [];
  permissions: Array<String> = [];
  organization: Object | null | undefined = null;
  otpAttachments: Array<FileItem> | null | undefined = [];

  // Instance check bcs. typescript
  private static instanceOfUser(object: any): object is IUser {
    if (object)
      return (
        "id" in object &&
        "name" in object &&
        "surname" in object &&
        "phone" in object &&
        "mobile" in object &&
        "email" in object &&
        "roles" in object &&
        "created_at" in object &&
        "updated_at" in object &&
        "deleted_at" in object &&
        "email_verified_at" in object &&
        "password_changed_at" in object
      );
    return false;
  };

  // Init
  private init(object?: IUser | null): void {
    if (Auth.instanceOfUser(object)) {
      this.id = object.id;
      this.name = object.name;
      this.surname = object.surname;
      this.phone = <Phone>{
        prefix: object.phone?.prefix,
        number: object.phone?.number,
      };
      this.mobile = <Phone>{
        prefix: object.mobile?.prefix,
        number: object.mobile?.number,
      };
      this.email = object.email;
      this.created_at = Date.parseData(object.created_at);
      this.updated_at = Date.parseData(object.updated_at);
      this.deleted_at = Date.parseData(object.deleted_at);
      this.email_verified_at = Date.parseData(object.email_verified_at);
      this.password_changed_at = Date.parseData(object.password_changed_at);
      this.roles = [];
      this.permissions = [];
      if (Array.isArray(object.roles)) {
        this.roles = Array.from(object.roles, (role) => role.name);
        object.roles.forEach((role) =>
          Array.isArray(role.permissions)
            ? (this.permissions = this.permissions.union(
                Array.from(role?.permissions, (permission) => permission.name)
              ))
            : null
        );
      }
      this.organization = object.organization;
      this.otpAttachments = object.otpAttachments;
    }
  }

  // C'tor
  public constructor(object?: IUser | null) {
    this.init(object);
  }

  // Logged is authenticated
  public logged(): boolean {
    return useSanctumAuth()?.isAuthenticated?.value;
  }

  // Login method
  public async login(data?: IAuth): Promise<any> {
    let auth = {};
    if (data) auth = data;
    else auth = this;
    if (Auth.instanceOfUser(auth) && !this.logged()) {
      try {
        auth = JSON.parse(JSON.stringify(auth)); // CANNOT SEND CLASS VIA API ONLY OBJECTS
        return await useSanctumAuth()?.login(auth);
      } catch (e) {
        if (e instanceof FetchError && e.response?.status === 422)
          console.log(e.response?._data?.errors);
        return e;
      }
    }
  }

  // Logout method
  public async logout(): Promise<any> {
    if (this.logged()) {
      try {
        clearNuxtData();
        return await useSanctumAuth()?.logout();
      } catch (e) {
        if (e instanceof FetchError && e.response?.status === 422)
          console.log(e.response?._data?.errors);
        return e;
      }
    }
  }

  // Refresh method
  public async refresh(): Promise<any> {
    try {
      return await useSanctumAuth()?.refreshIdentity();
    } catch (e) {
      if (e instanceof FetchError && e.response?.status === 422)
        console.log(e.response?._data?.errors);
      return e;
    }
  }

  // Permission check method
  public hasPermission(...permissions: Array<String>): boolean {
    let result = false;
    if (Array.isArray(permissions) && Array.isArray(this.permissions))
      for (const permission in permissions)
        if (this.permissions?.includes(permissions[permission]))
          return (result = true);
    return result;
  }

  // Role check method
  public hasRole(...roles: Array<String>): boolean {
    let result = false;
    if (Array.isArray(roles) && Array.isArray(this.roles))
      for (const role in roles)
        if (this.roles?.includes(roles[role])) return (result = true);
    return result;
  }

  // Permission check method
  public routeAvailable(to: any): boolean {
    let routePermission = to.name.split("-") as Array<String>;
    switch (routePermission[routePermission.length - 1]) {
      case "delete":
        routePermission[routePermission.length - 1] = "delete";
        break;
      case "edit":
      case "active":
        routePermission[routePermission.length - 1] = "update";
        break;
      case "clone":
      case "create":
        routePermission[routePermission.length - 1] = "create";
        break;
      default:
        routePermission.push("read");
        break;
    }
    routePermission = routePermission.filter((n: String) => n !== "id");
    // Permission direct exist
    if (this.hasPermission(routePermission.join("-"))) return true;
    // Permissions cascading exist
    if (Array.isArray(this.permissions)) {
      let permissionsCascade = [] as Array<String>;
      switch (routePermission.pop()) {
        case "read":
          permissionsCascade.push(routePermission.join("-") + "-read");
        case "create":
          permissionsCascade.push(routePermission.join("-") + "-create");
        case "update":
          permissionsCascade.push(routePermission.join("-") + "-update");
        case "delete":
          permissionsCascade.push(routePermission.join("-") + "-delete");
      }
      if (this.hasPermission(...permissionsCascade)) return true;
    }
    return false;
  }

  // Fetch profile
  public async fetchProfile(): Promise<any> {
    const client = useSanctumClient();
    return await client(useSanctumAppConfig()?.endpoints?.user, {
      method: "GET",
    });
  }
}

