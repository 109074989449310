import type { NuxtApp } from '#app/nuxt';
import type { AsyncData, AsyncDataOptions } from '#app/composables/asyncData';

export default function (key: String | string, handler?: (ctx?: NuxtApp) => Promise<ResT>, options?: AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, (NuxtErrorDataT extends Error | NuxtError ? NuxtErrorDataT : NuxtError<NuxtErrorDataT>) | DefaultAsyncDataErrorValue> {
  return refreshNuxtData(key as string)
    .then(() => {
      if (handler)
        return useAsyncData(key as string, handler, options);
      return useAsyncData(key as string, (ctx?: NuxtApp) => (requestBE(key) as Promise<ResT>), options);
    }) as AsyncData<PickFrom<DataT, PickKeys> | DefaultT, (NuxtErrorDataT extends Error | NuxtError ? NuxtErrorDataT : NuxtError<NuxtErrorDataT>) | DefaultAsyncDataErrorValue>;
}
