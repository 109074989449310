import unhead_ehlgyMh29Q from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._a7bsctf2cqpqrcldmyfrsspzpq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QI7DCfuoql from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._a7bsctf2cqpqrcldmyfrsspzpq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_eDByoPb1tx from "/var/www/html/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.9.0_sass@1.80_czqw2zcjf6exbbyfhoycrxhzsq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import auth_vT9JWWT9pN from "/var/www/html/plugins/auth.ts";
import payload_client_PojRY64Jfh from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._a7bsctf2cqpqrcldmyfrsspzpq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6yZKMtjFHU from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._a7bsctf2cqpqrcldmyfrsspzpq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_Ww6q5ehj2W from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._a7bsctf2cqpqrcldmyfrsspzpq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_hQnPkjMdTT from "/var/www/html/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._a7bsctf2cqpqrcldmyfrsspzpq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DdowNRp5Ou from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.7.2_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.9.0_sass@1.80.6_ters_khntn75qjkqwzu6jwjpark5ovi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_osmjM5HyxA from "/var/www/html/node_modules/.pnpm/nuxt-auth-sanctum@0.4.18_magicast@0.3.5_rollup@4.25.0/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import boot_OP3cd6SIk7 from "/var/www/html/plugins/boot.ts";
import window_mY4jZ4wBC5 from "/var/www/html/plugins/window.ts";
export default [
  unhead_ehlgyMh29Q,
  router_QI7DCfuoql,
  _0_siteConfig_eDByoPb1tx,
  auth_vT9JWWT9pN,
  payload_client_PojRY64Jfh,
  navigation_repaint_client_6yZKMtjFHU,
  chunk_reload_client_Ww6q5ehj2W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hQnPkjMdTT,
  plugin_DdowNRp5Ou,
  plugin_osmjM5HyxA,
  boot_OP3cd6SIk7,
  window_mY4jZ4wBC5
]