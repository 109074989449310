export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"name","content":"obchvat nula"},{"hid":"description","name":"description","content":"Firemná stránka spoločnosti Zero Bypass Limited | Company web page of Zero Bypass Limited."},{"hid":"og:description","property":"og:description","content":"Firemná stránka spoločnosti Zero Bypass Limited | Company web page of Zero Bypass Limited."},{"hid":"og:title","name":"og:title","content":"obchvat nula | Vonkajší obchvat hlavného mesta!"},{"hid":"og:image","property":"og:image","content":"undefinedfavicon.ico"},{"hid":"og:url","property":"og:url"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"innerHTML":""}],"noscript":[],"title":"obchvat nula | Vonkajší obchvat hlavného mesta!","htmlAttrs":{"lang":"sk"},"bodyAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = false

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false