// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Object {
    // DO NOT USE FOR GLOBAL !!!
  }

  interface ObjectConstructor {
    equal(valueA: Object, valueB: Object): boolean;
    isObject(value: any): boolean;
  }
}

Object.equal = function (valueA: Object, valueB: Object) {
  if (!Object.isObject(valueA) || !Object.isObject(valueB))
    return false;
  for (const p in valueA) {
    switch (typeof (valueA[p])) {
      case 'object':
        if (!Object.equal(valueA[p], valueB[p]))
          return false;
        break;
      case 'function':
        if ((typeof (valueB[p]) === 'undefined') || ((p !== 'equals') && (valueA[p].toString() !== valueB[p].toString())))
          return false;
        break;
      default:
        if (valueA[p] !== valueB[p])
          return false;
    }
  }
  for (const p in valueB)
    if ((typeof (valueA[p])) === 'undefined')
      return false;
  return true;
}

Object.isObject = function (value: any): boolean {
  return (((typeof value) === 'object') && (!Array.isArray(value)) && (value !== null))
}
