import { default as contactZjsR3yTbTmMeta } from "/var/www/html/pages/contact.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as loginB2KRong5x2Meta } from "/var/www/html/pages/login.vue?macro=true";
import { default as editRaQ0f497z8Meta } from "/var/www/html/pages/organization/[id]/edit.vue?macro=true";
import { default as createsCPemD3kWwMeta } from "/var/www/html/pages/organization/create.vue?macro=true";
import { default as editHMcu4oiNXFMeta } from "/var/www/html/pages/organization/edit.vue?macro=true";
import { default as indext7qj61Cl3iMeta } from "/var/www/html/pages/organization/index.vue?macro=true";
import { default as editbdWOuhlrMsMeta } from "/var/www/html/pages/otp/[id]/edit.vue?macro=true";
import { default as signaturejLLWGeAw4AMeta } from "/var/www/html/pages/otp/[id]/signature.vue?macro=true";
import { default as editxjCpHWedSuMeta } from "/var/www/html/pages/otp/attachment/[id]/edit.vue?macro=true";
import { default as indexTOxihAsyYxMeta } from "/var/www/html/pages/otp/attachment/index.vue?macro=true";
import { default as editwptku7Ep45Meta } from "/var/www/html/pages/otp/carrier/[id]/edit.vue?macro=true";
import { default as createvZYLyKgLS1Meta } from "/var/www/html/pages/otp/carrier/create.vue?macro=true";
import { default as index3RtayMJIE7Meta } from "/var/www/html/pages/otp/carrier/index.vue?macro=true";
import { default as createRPQlGPwiwAMeta } from "/var/www/html/pages/otp/create.vue?macro=true";
import { default as indexzuo8584qDEMeta } from "/var/www/html/pages/otp/index.vue?macro=true";
import { default as editOhd06ufJnzMeta } from "/var/www/html/pages/otp/request/[id]/edit.vue?macro=true";
import { default as resolvePckguQueyoMeta } from "/var/www/html/pages/otp/request/[id]/resolve.vue?macro=true";
import { default as createUL5n8hfqDsMeta } from "/var/www/html/pages/otp/request/create.vue?macro=true";
import { default as indexkTXB9d3p7nMeta } from "/var/www/html/pages/otp/request/index.vue?macro=true";
import { default as editqkjKJ3dfy0Meta } from "/var/www/html/pages/otp/signature/[id]/edit.vue?macro=true";
import { default as createFHBI7cRRX3Meta } from "/var/www/html/pages/otp/signature/create.vue?macro=true";
import { default as indexvD5gTGlz6PMeta } from "/var/www/html/pages/otp/signature/index.vue?macro=true";
import { default as editZReYyrUgquMeta } from "/var/www/html/pages/permission/[id]/edit.vue?macro=true";
import { default as indexPGirtpKFZcMeta } from "/var/www/html/pages/permission/index.vue?macro=true";
import { default as editMe6vd0KKFcMeta } from "/var/www/html/pages/profile/carrier/[id]/edit.vue?macro=true";
import { default as createGwxQXfIignMeta } from "/var/www/html/pages/profile/carrier/create.vue?macro=true";
import { default as indexRGfAoxoiavMeta } from "/var/www/html/pages/profile/carrier/index.vue?macro=true";
import { default as indexmfZblIq983Meta } from "/var/www/html/pages/profile/index.vue?macro=true";
import { default as editWFm8ghAi9oMeta } from "/var/www/html/pages/role/[id]/edit.vue?macro=true";
import { default as createVPCswjsR4tMeta } from "/var/www/html/pages/role/create.vue?macro=true";
import { default as indexARUrulUpICMeta } from "/var/www/html/pages/role/index.vue?macro=true";
import { default as editBG4kyUQSRSMeta } from "/var/www/html/pages/section/[id]/edit.vue?macro=true";
import { default as createRfaGjVIKJjMeta } from "/var/www/html/pages/section/create.vue?macro=true";
import { default as indexzEnwf3ImjBMeta } from "/var/www/html/pages/section/index.vue?macro=true";
import { default as editWlApzTFUM4Meta } from "/var/www/html/pages/user/[id]/edit.vue?macro=true";
import { default as create2Do7ksI3ciMeta } from "/var/www/html/pages/user/create.vue?macro=true";
import { default as indexRFTJOJ6cOLMeta } from "/var/www/html/pages/user/index.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    meta: contactZjsR3yTbTmMeta || {},
    component: () => import("/var/www/html/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexUbNeHw0dYYMeta || {},
    component: () => import("/var/www/html/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginB2KRong5x2Meta || {},
    component: () => import("/var/www/html/pages/login.vue")
  },
  {
    name: "organization-id-edit",
    path: "/organization/:id()/edit",
    meta: editRaQ0f497z8Meta || {},
    component: () => import("/var/www/html/pages/organization/[id]/edit.vue")
  },
  {
    name: "organization-create",
    path: "/organization/create",
    meta: createsCPemD3kWwMeta || {},
    component: () => import("/var/www/html/pages/organization/create.vue")
  },
  {
    name: "organization-edit",
    path: "/organization/edit",
    meta: editHMcu4oiNXFMeta || {},
    component: () => import("/var/www/html/pages/organization/edit.vue")
  },
  {
    name: "organization",
    path: "/organization",
    meta: indext7qj61Cl3iMeta || {},
    component: () => import("/var/www/html/pages/organization/index.vue")
  },
  {
    name: "otp-id-edit",
    path: "/otp/:id()/edit",
    meta: editbdWOuhlrMsMeta || {},
    component: () => import("/var/www/html/pages/otp/[id]/edit.vue")
  },
  {
    name: "otp-id-signature",
    path: "/otp/:id()/signature",
    meta: signaturejLLWGeAw4AMeta || {},
    component: () => import("/var/www/html/pages/otp/[id]/signature.vue")
  },
  {
    name: "otp-attachment-id-edit",
    path: "/otp/attachment/:id()/edit",
    meta: editxjCpHWedSuMeta || {},
    component: () => import("/var/www/html/pages/otp/attachment/[id]/edit.vue")
  },
  {
    name: "otp-attachment",
    path: "/otp/attachment",
    meta: indexTOxihAsyYxMeta || {},
    component: () => import("/var/www/html/pages/otp/attachment/index.vue")
  },
  {
    name: "otp-carrier-id-edit",
    path: "/otp/carrier/:id()/edit",
    meta: editwptku7Ep45Meta || {},
    component: () => import("/var/www/html/pages/otp/carrier/[id]/edit.vue")
  },
  {
    name: "otp-carrier-create",
    path: "/otp/carrier/create",
    meta: createvZYLyKgLS1Meta || {},
    component: () => import("/var/www/html/pages/otp/carrier/create.vue")
  },
  {
    name: "otp-carrier",
    path: "/otp/carrier",
    meta: index3RtayMJIE7Meta || {},
    component: () => import("/var/www/html/pages/otp/carrier/index.vue")
  },
  {
    name: "otp-create",
    path: "/otp/create",
    meta: createRPQlGPwiwAMeta || {},
    component: () => import("/var/www/html/pages/otp/create.vue")
  },
  {
    name: "otp",
    path: "/otp",
    meta: indexzuo8584qDEMeta || {},
    component: () => import("/var/www/html/pages/otp/index.vue")
  },
  {
    name: "otp-request-id-edit",
    path: "/otp/request/:id()/edit",
    meta: editOhd06ufJnzMeta || {},
    component: () => import("/var/www/html/pages/otp/request/[id]/edit.vue")
  },
  {
    name: "otp-request-id-resolve",
    path: "/otp/request/:id()/resolve",
    meta: resolvePckguQueyoMeta || {},
    component: () => import("/var/www/html/pages/otp/request/[id]/resolve.vue")
  },
  {
    name: "otp-request-create",
    path: "/otp/request/create",
    meta: createUL5n8hfqDsMeta || {},
    component: () => import("/var/www/html/pages/otp/request/create.vue")
  },
  {
    name: "otp-request",
    path: "/otp/request",
    meta: indexkTXB9d3p7nMeta || {},
    component: () => import("/var/www/html/pages/otp/request/index.vue")
  },
  {
    name: "otp-signature-id-edit",
    path: "/otp/signature/:id()/edit",
    meta: editqkjKJ3dfy0Meta || {},
    component: () => import("/var/www/html/pages/otp/signature/[id]/edit.vue")
  },
  {
    name: "otp-signature-create",
    path: "/otp/signature/create",
    meta: createFHBI7cRRX3Meta || {},
    component: () => import("/var/www/html/pages/otp/signature/create.vue")
  },
  {
    name: "otp-signature",
    path: "/otp/signature",
    meta: indexvD5gTGlz6PMeta || {},
    component: () => import("/var/www/html/pages/otp/signature/index.vue")
  },
  {
    name: "permission-id-edit",
    path: "/permission/:id()/edit",
    meta: editZReYyrUgquMeta || {},
    component: () => import("/var/www/html/pages/permission/[id]/edit.vue")
  },
  {
    name: "permission",
    path: "/permission",
    meta: indexPGirtpKFZcMeta || {},
    component: () => import("/var/www/html/pages/permission/index.vue")
  },
  {
    name: "profile-carrier-id-edit",
    path: "/profile/carrier/:id()/edit",
    meta: editMe6vd0KKFcMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/[id]/edit.vue")
  },
  {
    name: "profile-carrier-create",
    path: "/profile/carrier/create",
    meta: createGwxQXfIignMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/create.vue")
  },
  {
    name: "profile-carrier",
    path: "/profile/carrier",
    meta: indexRGfAoxoiavMeta || {},
    component: () => import("/var/www/html/pages/profile/carrier/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexmfZblIq983Meta || {},
    component: () => import("/var/www/html/pages/profile/index.vue")
  },
  {
    name: "role-id-edit",
    path: "/role/:id()/edit",
    meta: editWFm8ghAi9oMeta || {},
    component: () => import("/var/www/html/pages/role/[id]/edit.vue")
  },
  {
    name: "role-create",
    path: "/role/create",
    meta: createVPCswjsR4tMeta || {},
    component: () => import("/var/www/html/pages/role/create.vue")
  },
  {
    name: "role",
    path: "/role",
    meta: indexARUrulUpICMeta || {},
    component: () => import("/var/www/html/pages/role/index.vue")
  },
  {
    name: "section-id-edit",
    path: "/section/:id()/edit",
    meta: editBG4kyUQSRSMeta || {},
    component: () => import("/var/www/html/pages/section/[id]/edit.vue")
  },
  {
    name: "section-create",
    path: "/section/create",
    meta: createRfaGjVIKJjMeta || {},
    component: () => import("/var/www/html/pages/section/create.vue")
  },
  {
    name: "section",
    path: "/section",
    meta: indexzEnwf3ImjBMeta || {},
    component: () => import("/var/www/html/pages/section/index.vue")
  },
  {
    name: "user-id-edit",
    path: "/user/:id()/edit",
    meta: editWlApzTFUM4Meta || {},
    component: () => import("/var/www/html/pages/user/[id]/edit.vue")
  },
  {
    name: "user-create",
    path: "/user/create",
    meta: create2Do7ksI3ciMeta || {},
    component: () => import("/var/www/html/pages/user/create.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexRFTJOJ6cOLMeta || {},
    component: () => import("/var/www/html/pages/user/index.vue")
  }
]