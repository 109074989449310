export default defineNuxtPlugin((nuxtApp: any) => {
  const plugin = {
    install(app: any, options: any) {
      app.mixin({
        mounted() {
          window.addEventListener('resize', handleResize);
          handleResize;
        },
        unmounted() {
          window.removeEventListener('resize', handleResize);
        }
      });
      // Window resize
      const handleResize = () => {
        _size.width = window.innerWidth;
        _size.height = window.innerHeight;
      };
      let _size = {
        width: 0,
        height: 0
      };
      const windowSize = computed(() => _size);
      app.provide('windowSize', windowSize);
      app.provide('isMobileDevice', computed(() => ((windowSize?.width <= 640) || (windowSize?.height <= 640))));
    }
  };
  nuxtApp.vueApp?.use(plugin);
});
