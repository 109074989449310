export default defineNuxtPlugin((nuxtApp: any) => {
  const plugin = {
    install(app: any, options: any) {
      app.mixin({
        mounted() {

        },
        unmounted() {

        }
      });

      // Dev
      const inDev = computed(() => (process.env.NODE_ENV === 'development'));
      app.provide('inDev', inDev);
      // Info for public
      if (!inDev.value) {
        console.log('%c  STOP!', 'font-weight: bold; font-size: 100px; color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');
        let warningStyle = 'color: red; font-size: 20px';
        console.log('%c Toto je funkcia prehliadača určená pre vývojárov.', warningStyle);
        console.log('%c Ak vám niekto povedal, aby ste sem niečo', warningStyle);
        console.log('%c vložili a aktivovali tak to nerobte.', warningStyle);
        console.log('%c Jedná sa o podvod, vďaka ktorému môže dotyčný', warningStyle);
        console.log('%c získať prístup do vašeho účtu', warningStyle);
        console.log('%c alebo spustiť škodlivý kód.', warningStyle);
      }
    }
  };
  nuxtApp.vueApp?.use(plugin);
});
